<template>
  <div class="permission-container">
    <div class="buttonbc">
      <el-button @click="() => {
        $router.go(-1);
      }
        " size="small" class="back_btn">
        <img src="@/assets/back.png" alt="" />
      </el-button>
    </div>
    <div style="clear: both">
      <el-form :model="permissionForm" :rules="permissionRules" ref="permissionForm" label-width="200px"
        class="demo-ruleForm">
        <el-form-item label="角色名称" prop="role_name">
          <el-input v-model="permissionForm.role_name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="role_describe">
          <el-input v-model="permissionForm.role_describe" placeholder="最多200字" :maxlength="200"></el-input>
        </el-form-item>
        <el-form-item label="角色权限" prop="permission">
          <el-checkbox :indeterminate="allIndeterminate" v-model="allCheck" @change="handleCheckAll">全部选择</el-checkbox>
          <div v-for="item in permissions" :key="item.identifier" class="level_1">
            <el-row v-if="item.children.length > 0 && item.children.some(item2 => item2.show)" :gutter="20">
              <el-col :span="4">
                <el-checkbox v-model="item.checked" :label="item.title"
                  :indeterminate="checkIndeterminate(item.children)"
                  @change="handleCheckboxChanged(item)"></el-checkbox>
              </el-col>
              <el-col :span="20">
                <div v-for="_item in item.children.filter(j => j.show)" :key="_item.identifier"
                  class="checkbox-dropdown">
                  <el-checkbox v-if="_item.show" v-model="_item.checked" :label="_item.title"
                    :indeterminate="checkIndeterminate(_item.children)"
                    @change="handleCheckboxChanged(_item, item)"></el-checkbox>
                  <template v-if="_item.children && _item.children.length && _item.children.some(item3 => item3.show)">
                    <el-dropdown trigger="click" split-button :hide-on-click="false">
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="__item in _item.children" :key="__item.identifier">
                          <el-checkbox v-if="__item.show" v-model="__item.checked" :label="__item.title"
                            @change="handleCheckboxChanged(__item, _item)"></el-checkbox>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <el-tooltip v-if="_item.identifier === 'shopManagementPermission'" effect="dark" placement="top">
                    <label class="cursor-pointer">
                      <i class="el-icon-warning-outline"></i>
                    </label>
                    <div slot="content">
                      用于控制商城是否有【权限】弹窗功能项
                    </div>
                  </el-tooltip>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" size="small" style="margin-top: 15px" @click="saveRolePermission">保存设置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as API_Auth from "@/api/auth";
import * as API_Menus from "@/api/menus";
import * as API_order from "@/api/order";

export default {
  name: "rolePermission",
  data () {
    return {
      /** 权限 表单 */
      permissionForm: {
        role_name: "",
        role_describe: "",
      },
      /** 权限 表单规则 */
      permissionRules: {
        role_name: [
          this.MixinRequired("请输入角色名称！"),
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      // 全选状态
      allCheck: false,
      // 全选不确定状态
      allIndeterminate: false,
    };
  },
  mounted () {
    this.role_id = Number(this.$route.params.id);
    this.GET_RolePermission();
  },
  beforeRouteUpdate (to, from, next) {
    this.role_id = to.params.id;
    next();
  },
  activated () {
    this.role_id = this.$router.params.id;
  },
  watch: {
    role_id: "GET_RolePermission",
  },
  methods: {
    /** 全选 */
    handleCheckAll (checked) {
      this.allIndeterminate = false;
      this.$set(
        this,
        "permissions",
        this.setPermissionsCheck(this.permissions, checked)
      );
    },
    /** 选择 */
    handleCheckboxChanged (item, parent) {
      if (item.children && item.children.length) {
        this.$set(
          item,
          "children",
          this.setPermissionsCheck(item.children, item.checked)
        );
      }
      this.countAllPermissions();
      this.countParentChecked();
    },
    /** 设置权限状态 */
    setPermissionsCheck (permissions, checked) {
      const perm = this.MixinClone(permissions);
      perm.map((item) => {
        item.checked = checked;
        this.$set(item, "checked", checked);
        if (item.children && item.children.length) {
          this.$set(
            item,
            "children",
            this.setPermissionsCheck(item.children, checked)
          );
        }
      });
      return perm;
    },
    /** 检测是否有不确定性 */
    checkIndeterminate (permissions) {
      if (!Array.isArray(permissions)) return false;
      const _len = permissions.length;
      const __len = permissions.filter((item) => item.checked).length;
      return __len !== 0 && _len !== __len;
    },
    /** 获取所有权限展开后的长度、被选中的长度 */
    countAllPermissions (permissions) {
      permissions = permissions || this.permissions;
      const _list = [];
      permissions.forEach((item) => {
        _list.push(item);
        if (item.children)
          _list.push(...this.countAllPermissions(item.children));
      });
      const length = _list.length;
      const length_checked = _list.filter((_item) => _item.checked).length;
      this.allCheck = length === _list.filter((_item) => _item.checked).length;
      this.allIndeterminate = length_checked !== 0 && length !== length_checked;
      return _list;
    },
    /** 计算所有父辈的选中状态 */
    countParentChecked (permissions) {
      permissions = permissions || this.permissions;
      permissions.forEach((item) => {
        if (item.children && item.children.length) {
          this.countParentChecked(item.children);
          const lenght = item.children.length;
          const checked_length = item.children.filter(
            (_item) => _item.checked
          ).length;
          item.checked = !!checked_length;
        }
      });
    },
    /** 保存角色权限 */
    saveRolePermission () {
      this.$refs["permissionForm"].validate((valid) => {
        if (valid) {
          const params = {
            ...this.permissionForm,
            menus: this.permissions,
          };
          this.role_id === 0
            ? API_Auth.addRole(params).then(() => saveSuccess())
            : API_Auth.editRole(this.role_id, params).then(() => saveSuccess());
          const saveSuccess = () => {
            this.$message.success("保存成功！");
            // console.log(this.$route,"this.$route.paramsthis.$route.params")
            // console.log(this.$router,"this.$route.paramsthis.$route.params")
            // this.$route.params.callback()
            this.$router.push("/shop-auth/role-manage");
            // this.$store.dispatch('delCurrentViews', {
            //   view: this.$route,
            //   $router: this.$router
            // })
          };
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 获取权限菜单树 */
    async GET_RolePermission () {
      const res = await API_Menus.getMenusChildren()
      const res1 = await API_order.getShopExtAuth()
      const jurisdictionList = [
        "couponOrderList",
        "writeOffOpenCard",
        "cardSearch",
        "closeCardWrite",
        "openCardWrite",
        "saleCardOpenManagement",
        "saleCardCloseManagement",
        "customerProfile",
        "cardOpeningPersonnelManagement",
        "cardUserAgreement",
        "customizedDiscountTemplateManagement",
        "cardSaleManage",
        "supplementaryRecordingOfInvoiceInformation",
        "customerAccountApproval",
        "customizedDiscountApproval",
        "cardAndInvoiceReview",
        "verificationManagement",
        "customerRechargeReview",
        "manualAccountRecharge",
        "projectSettlementConfirmation",
        "departmentalApprovalList",
        "financialReviewList",
        "departmentalApprovalListDZ",
        "financialReviewListDZ",
      ];
      if (res1.open !== "OPEN") {
        const arr = [];
        res.forEach((item, index) => {
          item.children.forEach((item1, index1) => {
            if (jurisdictionList.indexOf(item1.identifier) !== -1) {
              item1.show = false;
              arr.push({ index, index1 });
            } else {
              item1.show = true;
            }
            if (item1.children.length > 0) {
              item1.children.forEach(k => {
                if (jurisdictionList.indexOf(k.identifier) !== -1) {
                  k.show = false;
                  arr.push({ index, index1 });
                } else {
                  k.show = true;
                }
              })
            }
          });
        });
        console.log(res);
        let i = -1;
        let num = 0;
        arr.forEach((item) => {
          if (item.index === i) {
            console.log(num);
            // res[item.index].children.splice(item.index1 - num, 1);
            num++;
          } else {
            i = item.index;
            num = 0;
            // res[item.index].children.splice(item.index1 - num, 1);
            num++;
          }
        });
      } else {
        res.forEach((item, index) => {
          item.children.forEach((item1, index1) => {
            item1.show = true;
            if (item1.children.length > 0) {
              item1.children.forEach(k => {
                k.show = true;
              })
            }
          });
        });
      }

      console.log(res1);
      // 如果this.role_id 不为0，说明是编辑。
      if (this.role_id !== 0) {
        API_Auth.getRoleDetail(this.role_id).then((response) => {
          if (res1.open !== "OPEN") {//销售开卡权限关闭时
            response.menus.forEach(items => {
              if (items.children.some(k => !(jurisdictionList.includes(k.identifier)) && k.checked == true)) {
                items.checked = true
              } else {
                items.checked = false
              }
            })
          }
          this.role_id = response.role_id;
          this.permissionForm.role_name = response.role_name;
          this.permissionForm.role_describe = response.role_describe;
          const checkedIds = this.expandRouters(response.menus);
          console.log(
            this.sortMenus(this.filterRoleRouter(res, checkedIds))
          );
          this.$set(
            this,
            "permissions",
            this.sortMenus(this.filterRoleRouter(res, checkedIds))
          );
          this.countAllPermissions();
        });
      } else {
        this.$set(this, "permissions", this.sortMenus(res));
        this.countAllPermissions();
      }

      if (res1.numof_enterprise_owner_suppliers === -1) {
        const findIndex = res.findIndex(item => item.identifier === 'suppliers')
        res.splice(findIndex, 1)
      }

    },
    /** 由于后期项目需求变更，数据库中的id会发生了改变，导致与页面上展示的左侧菜单的顺序不一致，所以此处做个调整，调整成与页面显示的一致 */
    sortMenus (arr) {
      const shopMenus = {
        页面: 0,
        商品: 1,
        用户: 2,
        订单: 3,
        财务: 4,
        数据: 5,
        设置: 6,
        工具: 7,
        店员: 8,
        通知: 9,
      },
        enterpriseMenus = {
          首页: 0,
          集市: 1,
          订单: 2,
          财务: 3,
          数据: 4,
          应用: 5,
          设置: 6,
          权限: 7,
          通知: 8,
        },
        supplierMenus = {
          首页: 0,
          商品: 1,
          订单: 2,
          财务: 3,
          数据: 4,
          设置: 5,
          通知: 6,
        };
      let menus = null;
      if (
        this.$store.getters.user.shop_type === 1 ||
        !this.$store.getters.user.shop_type
      ) {
        // 商家（从企业跳转到商家时显示shop_type为空）
        menus = shopMenus;
      }
      if (this.$store.getters.user.shop_type === 3) {
        // 企业
        menus = enterpriseMenus;
      }
      return arr.sort((a, b) => {
        return menus[a.title] - menus[b.title];
      });
    },
    /** 展开路由的identifier */
    expandRouters (menus) {
      const routers = [];
      menus.forEach((item) => {
        item.checked && routers.push(item.identifier);
        if (item.children && item.children.length) {
          routers.push(...this.expandRouters(item.children));
        }
      });
      return routers;
    },
    /** 递归筛选被选中的路由 */
    filterRoleRouter (routers, ids) {
      const _routers = [];
      routers.forEach((item) => {
        if (ids.includes(item.identifier) || item.hidden) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        if (item.children) {
          this.$set(
            item,
            "children",
            this.filterRoleRouter(item.children, ids)
          );
        }
        _routers.push(item);
      });
      return _routers;
    },
  },
};
</script>

<style lang="scss" scoped>
.permission-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.level_1 {
  padding: 15px 0;
  border-bottom: 1px dashed #e7e7e7;

  &:last-child {
    border-bottom: none;
  }
}

/deep/ {
  .el-form-item__label {
    padding-top: 15px;
  }

  .el-form-item__label::after {
    top: 60% !important;
  }

  .el-form-item__content {
    border-left: 1px solid #e7e7e7;
    padding-left: 20px;
    padding-top: 15px;

    .el-form-item__error {
      padding-left: 20px;
    }
  }

  .el-form-item:last-child {
    .el-form-item__content {
      padding-top: 0;
    }
  }

  .el-form-item:not(:first-child) {
    border-top: 1px solid #e7e7e7;
    position: relative;

    &::after {
      content: " ";
      width: 1px;
      height: 22px;
      background-color: #e7e7e7;
      position: absolute;
      top: -22px;
      left: 200px;
    }
  }

  .el-button-group {
    display: inline-block;

    .el-button {
      display: inline-block;
      padding: 0;
      border: none;

      &:focus,
      &:hover {
        color: #606266;
        border-color: #fff;
        background-color: #fff;
      }
    }
  }
}

.checkbox-dropdown {
  display: inline-block;
  min-width: 130px;
  cursor: pointer;

  .checked {
    color: #1a43a9;
  }
}

/deep/ .el-checkbox {
  margin-right: 0;
}
</style>
